<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col m-auto mx-auto max-w-l p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="replenishBalanceModalLabel">
          Пополнить баланс
        </h5>
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body">
        <!-- <p>С баланса списывается ТОЛЬКО стоимость услуг за сервис</p> -->
        <div class="alert alert-info" role="alert">
          К сожалению, на данный момент, пополнение баланса доступно только
          через нашего администратора.
          <br />Для этого перейдите в нашу группу в Телеграме и напишите в чат.
        </div>
        <TelegramButton :href="`https://t.me/+4j1dwdCK7DhmNDRi`">
          Перейти в Telegram
        </TelegramButton>
        <!-- <div>
          <h6>Сумма к пополнению</h6>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-primary">1000₽</button>
            <button type="button" class="btn btn-outline-primary">1500₽</button>
            <button type="button" class="btn btn-outline-primary">3000₽</button>
            <button type="button" class="btn btn-outline-primary">5000₽</button>
          </div>
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Электронная почта*</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="name@example.com"
          />
        </div> -->
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Отмена
        </button>
        <button type="button" class="btn btn-primary">Пополнить</button>
      </div> -->
    </div>
  </VueFinalModal>
</template>
<script setup lang="ts">
import { defineEmits } from "vue";
import { VueFinalModal } from "vue-final-modal";
import TelegramButton from "@/components/UI/TelegramButton.vue";
import CloseButton from "@/components/UI/CloseButton.vue";

const emit = defineEmits<{
  (e: "closeicon"): void;
}>();
</script>
